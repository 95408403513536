import React from 'react'
import './Reasons.css'
import image5 from "../../assets/image5.jpg";
import image7 from "../../assets/image7.jpg";
import image8 from "../../assets/image8.jpg";
import image6 from "../../assets/image6.jpg";
import lockers from '../../assets/lockers.png'
import saunas from '../../assets/saunas.png'
import wifi from '../../assets/wifi.png'
import tick from "../../assets/tick.png";
const Reasons = () => {
  return (
    <div className="Reasons" id='reason'>
        <div className="left-r">
            <img src={image5} alt="" />
            <img src={image7} alt="" />
            <img src={image8} alt="" />
            <img src={image6} alt="" />
        </div>
        <div className="right-r">
            <span>Keunggulan</span>

            <div>
                <span className="stroke-text">Kenapa </span>
                <span>Berlatih di Maximum?</span>
            </div>

            <div className='details-r'>
                <div>
                    <img src={tick} alt="" />
                    <span>Dilatih oleh coach profesional</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>Program latihan bervariasi</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>Terdapat fasilitas penunjang Sauna</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>Locker room yang memadai & Hot shower</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>Free Wi Fi</span>
                </div>
            </div>
            <span 
                style={{
                    color : 'var(--gray)',
                    fontWeight: 'normal',
                    }}
                >
                    Fasilitas Penunjang Kami
            </span>

            <div className="partners">
                <img src={saunas} alt="" />
                <img src={lockers} alt="" />
                <img src={wifi} alt="" />
            </div>
        </div>
    </div>
  );
};

export default Reasons;