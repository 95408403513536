export const programsData = [
  {
    image: (
      <svg
        width="16"
        height="15"
        viewBox="0 0 640 512"
        
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M104 96h-48C42.75 96 32 106.8 32 120V224C14.33 224 0 238.3 0 256c0 17.67 14.33 32 31.1 32L32 392C32 405.3 42.75 416 56 416h48C117.3 416 128 405.3 128 392v-272C128 106.8 117.3 96 104 96zM456 32h-48C394.8 32 384 42.75 384 56V224H256V56C256 42.75 245.3 32 232 32h-48C170.8 32 160 42.75 160 56v400C160 469.3 170.8 480 184 480h48C245.3 480 256 469.3 256 456V288h128v168c0 13.25 10.75 24 24 24h48c13.25 0 24-10.75 24-24V56C480 42.75 469.3 32 456 32zM608 224V120C608 106.8 597.3 96 584 96h-48C522.8 96 512 106.8 512 120v272c0 13.25 10.75 24 24 24h48c13.25 0 24-10.75 24-24V288c17.67 0 32-14.33 32-32C640 238.3 625.7 224 608 224z"/></svg>
    ),
    heading: "WEIGHTLIFTING",
    details:
      "Lancarkan peredaran darahmu dan rasakan banyak manfaat lain dari sauna maximum GYM",
  },
  {
    image: <svg width="16" height="17" viewBox="0 0 256 256"  xmlns="http://www.w3.org/2000/svg">
   <path d="M256,160a48,48,0,1,1-78.7-36.8L201.1,164a8,8,0,1,0,13.8-8l-23.8-40.9A47,47,0,0,1,208,112,48,48,0,0,1,256,160ZM48,168a8.2,8.2,0,0,1-4.7-1.5,8,8,0,0,1-1.8-11.2l27.9-38.2A46.6,46.6,0,0,0,48,112a48.1,48.1,0,1,0,34.3,14.5L54.5,164.7A7.9,7.9,0,0,1,48,168ZM165.9,72H192a8,8,0,0,1,8,8,8,8,0,0,0,16,0,24.1,24.1,0,0,0-24-24H152a7.8,7.8,0,0,0-6.9,4,7.7,7.7,0,0,0,0,8l11.6,20H99.3L82.9,60A7.9,7.9,0,0,0,76,56H48a8,8,0,0,0,0,16H71.4L85.1,95.5,69.4,117.1a48.1,48.1,0,0,1,12.9,9.4l11.6-15.9L125.1,164a8,8,0,1,0,13.8-8l-30.3-52h57.5l11.2,19.2a46.5,46.5,0,0,1,13.8-8.1Z"/> </svg>,
    heading: "SPINNING CLASS",
    details:
      "Bawa barangmu lebih banyak dan amankan barang bawaanmu agar bisa berlatih dengan tenang",
  },
  {
    image: <svg width="16" height="17" viewBox="0 0 576 512"  xmlns="http://www.w3.org/2000/svg">
    <path d="M352.4 243.8l-49.83 99.5c-6.009 12-23.41 11.62-28.92-.625L216.7 216.3l-30.05 71.75L88.55 288l176.4 182.2c12.66 13.07 33.36 13.07 46.03 0l176.4-182.2l-112.1 .0052L352.4 243.8zM495.2 62.86c-54.36-46.98-137.5-38.5-187.5 13.06L288 96.25L268.3 75.92C218.3 24.36 135.2 15.88 80.81 62.86C23.37 112.5 16.84 197.6 60.18 256h105l35.93-86.25c5.508-12.88 23.66-13.12 29.54-.375l58.21 129.4l49.07-98c5.884-11.75 22.78-11.75 28.67 0l27.67 55.25h121.5C559.2 197.6 552.6 112.5 495.2 62.86z"/></svg>
  ,
    heading: "ZUMBA CLASS & AEROBIC",
    details:
      "Bakar kalori berlebih ditubuh dan rasakan manfaat lainnya dengan cara yang menyenangkan",
  },
  {
    image: <svg width="20" height="16" viewBox="0 0 448 512"  xmlns="http://www.w3.org/2000/svg"  >
    <path d="M448 144v120.4C448 314.2 422.6 358.1 384 384v128H128v-128l-53.19-38.67C48 325.8 32 294.3 32 261.2V192c0-14.58 6.625-28.38 17.1-37.48L80 130.5V176C80 184.8 87.16 192 96 192s16-7.164 16-16v-128C112 21.48 133.5 0 160 0c25.38 0 45.96 19.77 47.67 44.73C216.2 36.9 227.5 32 240 32C266.5 32 288 53.48 288 80v5.531C296.6 72.57 311.3 64 328 64c23.47 0 42.94 16.87 47.11 39.14C382.4 98.7 390.9 96 400 96C426.5 96 448 117.5 448 144z"/></svg>
    ,
    heading: "AIKIDO",
    details:
      "Bekali dirimu dengan aikido untuk keamanan juga tenangkan pikiran dan energi di tubuhmu",
  },
];



