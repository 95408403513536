import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import heart from "../../assets/heart.png";
import calories from "../../assets/calories.png";
import NumberCounter from 'number-counter'

import { motion } from 'framer-motion';

const Hero = () => {

  const transition = {type: 'spring', duration : 3}
  const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className="hero" id='home'>

        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
            {/* the best ad */}
            <div className="the-best-ad">
              <motion.div
              initial={{left: mobile? "210px": '238px'}}
              whileInView={{left: '8px'}}
              transition={{...transition, type: 'tween'}}
              >
              </motion.div>
              <span>The Best GYM Center in Purwokerto</span>
            </div>

            {/* Hero Heading */}
            <div className="hero-text" >
              <div>
                <span className='stroke-text'>SHAPE </span>
                <span>YOUR</span>
                </div>
                <div><span>BODY</span>
                </div>
                <div>
                  <span>
                    OPEN DAILY 06.00 - 22.00
                  </span><br/><br/>
                  <span>
                  Jl H. R. Bunyamin No.80, Purwokerto Utara, Jawa Tengah
                  </span>
                </div>
            </div>

            {/* Figures */}
            <div className="figures">
              <div>
                <span>
                  <NumberCounter end={140} start={100} delay='4' preFix="+" />
                </span>
                <span>Athletes</span>
              </div>
              <div>
                <span>
                <NumberCounter end={100} start={50} delay='3' preFix="+" />
                </span>
                <span>Member Joined</span>
              </div>
              <div>
                <span>
                <NumberCounter end={4} start={0} delay='3'/>
                </span>
                <span>Programs</span>
              </div>
            </div>

            {/*Hero Button*/}
            <div className="hero-buttons">
              <button className="btn"><a class="btn-gabung" href="https://api.whatsapp.com/send/?phone=6281392475818&text=Halo+kak+aku+mau+gabung+sama+maximum&type=phone_number&app_absent=0" target="_blank">Gabung</a></button>
              <button className="btn"> <a class="btn-info" href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x2e655f4dd84c2789:0x528eaf6d6797733a?utm_source=mstt_1" target="_blank">Alamat</a></button>
            </div>
        </div>
        <div className="right-h">
          <a className="b-gbg" href="https://api.whatsapp.com/send/?phone=%2B6281392475818&text&type=phone_number&app_absent=0" target="_blank">

          <button className="btn">Gabung</button>
          </a>

          <motion.div 
          transition={transition}
          whileInView={{ right: '4rem'}}
          initial={{ right: '-1rem'}}
          className="heart-rate">
            <img src={heart} alt="" />
            <span>Heart Rate</span>
            <span>116 bpm</span>
          </motion.div>

          {/* hero images */}
          <img src={hero_image} alt="" className="hero-image" />
          <motion.img 
          initial={{ right: "11rem"}}
          whileInView={{right: "20rem"}}
          transition={transition}
          src={hero_image_back} alt="" className="hero-image-back" />

          {/* calories */}
          <motion.div 
          initial={{ right: "37rem"}}
          whileInView={{ right: "28rem"}}
          transition={transition}
          className="calories">
            <img src={calories} alt="" />
            <div>
            <span>Calories&nbsp;Burned</span>
            <span>220 kcal</span>
            </div>
          </motion.div>
        </div>
    </div>
  );
};

export default Hero