import React from "react";
import {plansData} from "../../data/plansData";
import whiteTick from '../../assets/whiteTick.png'
import "./Plans.css";
const Plans = () => {
  return (
    <div className="plans-container">
        <div className="blur plans-blur-1"></div>
        <div className="blur plans-blur-2"></div>
        <div className="program-header" style={{gap: '2rem'}}>
            <span className="stroke-text">MARI BERGABUNG</span>
            <span>BERSAMA</span>
            <span className="stroke-text">KAMI</span>
        </div>

        {/*plans card*/}
        <div className="plans">
            {plansData.map((plan, i)=>(
                <div className="plan" key={i}>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>Rp {plan.price}</span>

                    <div className="features">
                        {plan.features.map((feature, i)=>(
                            <div className="feature">
                                <img src={whiteTick} alt="" />
                                <span key={i}>{feature}</span>
                            </div>
                        ))}
                    </div>


                    <button className="btn"> <a className="p-btn" href="https://api.whatsapp.com/send/?phone=%2B6281392475818&text&type=phone_number&app_absent=0" target="_blank"> Gabung Sekarang </a></button>
                </div>
            ))}
        </div>
    </div>
  );
};

export default Plans;