import React from 'react'
import './Footer.css'
import Instagram from "../../assets/instagram.png";
import link from "../../assets/link.png";
import shopee from "../../assets/shopee.png";
import maximum from "../../assets/maximum.png";
const Footer = () => {
  return (
    <div className="Footer-Container" id='footer'>
        <hr />
        <div className="footer">
            <div className="social-links">
            <a href="https://www.instagram.com/maximumgym.purwokerto/" target={'_blank'}><img src={Instagram} alt="" /></a>
            <a href="https://shp.ee/3ajpy5s" target={'_blank'}><img src={shopee} alt="" /></a>
        </div>
        <div className="logo-f">
            <img src={maximum} alt="" />
            </div>

        <div className="copyright">Copyright © Sindhu Nugroho. All Right Reserved</div>
        </div>

        <div className="blur blur-f-1"></div>
        <div className="blur blur-f-2"></div>

      
    </div>
  );
};

export default Footer;