import image1 from "../assets/mredi.png";
import image2 from "../assets/mrdedi.png";
import image3 from "../assets/mrdafit.png";
import image4 from "../assets/mrfani.png";
import image5 from "../assets/mrmerry.png";

export const testimonialsData = [
  {
    image: image1,
    review:[
      "Individu : Rp 1.000.000/Person (10x)",
      <br/>,
      "Couple : Rp 1.600.000/2 Person (10x)",
      <br/>,
      "Per Visit : Rp 150.000/Person (1x)",
    ],
    name: 'MR. EDI',
    status : 'TRAINER'
    
  },
  {
    image: image2,
    review:[ 
    "Individu : Rp 600.000/Person (7x)",
    <br/>,
    "Couple : Rp 1.000.000/2 Person (7x)",
    <br/>,
    "Per Visit : Rp 120.000/Person (1x)",
    ],
    name: 'MR. DEDI',
    status: 'TRAINER'
  },
  {
    image : image3,
    review: [
    "Individu : Rp 600.000/Person (7x)",
    <br/>,
    "Couple : Rp 1.000.000/2 Person (7x)",
    <br/>,
    "Per Visit : Rp 120.000/Person (1x)",
    ],
    name: 'MR. DAFIT',
    status: "TRAINER"
  },

  {
    image : image4,
    review: [
    "Individu : Rp 600.000/Person (7x)",
    <br/>,
    "Couple : Rp 1.000.000/2 Person (7x)",
    <br/>,
    "Per Visit : Rp 120.000/Person (1x)",
  ],
    name: 'MR. FANI',
    status: "TRAINER"
  },

  {
    image : image5,
    review: [
    "Individu : Rp 600.000/Person (7x)",
    <br/>,
    "Couple : Rp 1.000.000/2 Person (7x)",
    <br/>,
    "Per Visit : Rp 120.000/Person (1x)",
  ],
    name: 'MR. MERRY PUTRA',
    status: "TRAINER"
  }
];
