import React, { useState } from "react";
import "./Header.css";
import maximum from "../../assets/maximum.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";
const Header = () => {

  const mobile = window.innerWidth<= 768 ? true: false;
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <div className="header">

        <img src={maximum} alt="" className='maximum'/>

        {menuOpened === false && mobile === true ? (
          <div
          style={{
            backgroundColor: "var(--appColor)",
            padding: "0.5rem", 
            borderRadius: "5px",
            }}

            onClick={()=> setMenuOpened(true)}
          >
            <img
            src={Bars} 
            alt="" 
            style={{width: '1.5rem', height: '1.5rem'}} 
            />
            </div>
        ) : (
        <ul className="header-menu">
          <li>
          <Link
          onClick={()=>setMenuOpened(false)}
          activeClass="active"
          to='home'
          spy={true}
          smooth={true}
          >Home</Link>
          </li>

          <li onClick={()=>setMenuOpened(false)}><Link
          onClick={()=>setMenuOpened(false)}
          to='programs'
          spy={true}
          smooth={true}
          >Program</Link>
          </li>
          
          <li onClick={()=>setMenuOpened(false)}><Link
          onClick={()=>setMenuOpened(false)}
          to='reason'
          spy={true}
          smooth={true}
          >Tentang</Link>
          </li>
          
          <li onClick={()=>setMenuOpened(false)}><Link
          onClick={()=>setMenuOpened(false)}
          to='plans-container'
          spy={true}
          smooth={true}
          >Pricelist</Link>
          </li>
          
          <li><Link
          onClick={()=>setMenuOpened(false)}
          to='testimonials'
          spy={true}
          smooth={true}
          >Trainer</Link>
          </li>

          <li><Link
          onClick={()=>setMenuOpened(false)}
          to='join-us'
          spy={true}
          smooth={true}
          >Newsletter</Link>
          </li>
        </ul>
        )}
    </div>
  )
}

export default Header